import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { ColumnistLarge } from '../../components/ColumnistTemplate'
import Layout from '../../components/Layout'
import SectionTitle from '../../components/SectionTitle'
import '../../styles/ColumnistIndex.sass'

const MAX_ARTICLE_PER_COLUMN = 3

const ColumnistPage = (props) => {
  const columnistList = props.data.columnistList.edges.map(data => data.node)
  const articleWithColumnist = props.data.articleList.edges.map(data => data.node)
  const title = props.data.site.siteMetadata.title

  // group article by column
  const articleByColumn = {}
  articleWithColumnist.forEach(article => {
    const thisColumnist = article.frontmatter.columnist.meta.title
    if (!articleByColumn[thisColumnist]) {
      articleByColumn[thisColumnist] = []
    }
    articleByColumn[thisColumnist].push(article)
  })
  columnistList.forEach(columnist => {
    const title = columnist.frontmatter.title
    const articlesInColumn = articleByColumn[title]
    if (articlesInColumn) {
      columnist.articles = articlesInColumn.slice(0, MAX_ARTICLE_PER_COLUMN)
      columnist.isArticleTooMuch = articlesInColumn.length > MAX_ARTICLE_PER_COLUMN
    }
  })
  return (
    <Layout>
      <section className="section columnist">
        <Helmet title={`专栏 | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1>
                <SectionTitle>专栏</SectionTitle>
              </h1>
              {columnistList.map(columnist => (
                <div className="columns" key={columnist.id}>
                  <div className="column is-3">
                    <ColumnistLarge
                      slug={columnist.fields.slug}
                      title={columnist.frontmatter.title}
                      cover={columnist.frontmatter.cover}
                    />
                  </div>
                  <div className="column is-9 columnist__articles articles">
                    {columnist.articles ? columnist.articles.map(article => (
                      <Link to={article.fields.slug} className="articles__item dim" key={article.id}>
                        <div className="articles__title">
                          {article.frontmatter.title}
                        </div>
                        <div className="articles__excerp">
                          {article.excerpt}
                        </div>
                      </Link>
                    )) : '该专栏暂时未有新文章'}
                    {columnist.isArticleTooMuch ? (
                      <div className="articles__more">
                        <Link to={columnist.fields.slug}>
                          <button className="button">所有文章</button>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}


export default ColumnistPage

export const columnistPageQuery = graphql`
  query ColumnistsQuery {
    site {
      siteMetadata {
        title
      }
    }
    columnistList: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___order] }
      filter: { frontmatter: { templateKey: { eq: "columnist" } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title: columnist_title,
            cover {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    articleList: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { 
        frontmatter: {
          templateKey: { eq: "article" },
          columnist: {
            frontmatter: { columnist_title: { regex: "/[^ ]+/" } }
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          excerpt(pruneLength: 60, truncate: true)
          frontmatter {
            title,
            columnist {
              ...simpleColumnistFields
            }
          }
        }
      }
    }
  }
`

