import React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import '../styles/ColumnistTemplate.sass'

const GeneralBackground = ({ img, className = '' }) => {
  if (!img) {
    return null
  }
  if (typeof img === 'string') {
    return (
      <div className={`${className} generalimg`} style={{backgroundImage: `url('${img}')`}}></div>
    )
  } else if (img.childImageSharp && img.childImageSharp.fluid) {
    return (
      <BackgroundImage className={className} fluid={img.childImageSharp.fluid} />
    )
  } else {
    console.error(`Invalid background img: ${img}`)
    return null
  }
}

const ColumnistLarge = ({title, cover, slug}) => (
  <div className="collarge">
    <Link to={slug}>
      <figure className="image is-16by9">
        <div className="collarge__cover">
          {cover ? (
            <GeneralBackground img={cover} />
          ) : <div className="bg-default" />}
        </div>
      </figure>
    </Link>
    <Link to={slug}>
      <h2>{title}</h2>
    </Link>
  </div>
)

const ColumnistLiner = ({title, cover}) => (
  <div className="colliner">
    <GeneralBackground
      className="colliner__cover"
      img={cover}
    />
    <span>{title}</span>
  </div>
)

export {
  ColumnistLarge,
  ColumnistLiner
}
